import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { toast } from "sonner";
import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import { computed } from 'zustand-middleware-computed-state';
import { uniqBy } from "lodash";

export const useAiPromptForbiddenStore = create(
    persist(
        computed(
            (set) => ({
                list: [],
                setList: (list) => set({ list }),
                reset: () => set({ list: [] }),
            }),
            (state) => {
                function getForbiddenWords(text) {
                    const splitted = text?.split(" ") || [];
                    const forbiddenWords = state.list.filter((item) => splitted.includes(item.words));
                    return forbiddenWords;
                }
                return { getForbiddenWords }
            }
        )
    )
)

export const useAiPromptForbidden = () => {

    const setList = useAiPromptForbiddenStore((state) => state.setList);

    const { data } = useQuery({
        queryKey: ['forbidden-words'],
        queryFn: () => window.axios.get(route('forbidden.paginate', { per_page: 1000 })).then(({ data }) => data?.data),
        enabled: true,
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    useEffect(() => {
        setList(data);
    }, [data])

    const getForbiddenWords = useCallback((text) => {
        const splitted = text?.toLowerCase()?.split(" ") || [];
        const filtered = data.filter((item) => splitted.includes(item.words)) || []
        return filtered?.length > 0 ? {
            words: filtered.map((item) => item.words).join(', '),
            types: uniqBy(filtered, v => v.type).map((item) => item.type).join(', '),
        } : undefined;
    }, [data])

    return { getForbiddenWords }
}


export const useAiPromptResponseStore = create(
    subscribeWithSelector(
        persist(
            (set) => ({
                response: null,
                selected: null,
                setSelected: (selected) => set({ selected }),
                setResponse: (response) => set({ response }),
                reset: () => set({ prompt: '', response: null, selected: null }),
            })
        )
    )
)

export const useAiPromptResponse = () => {
    const { response, setResponse, selected, setSelected } = useAiPromptResponseStore();

    // useEffect(() => {
    //     console.log("useAiPromptResponse:", response?.uuid)
    //     const channel = window.Echo.channel(`ai-image.${response?.uuid}`);
    //     channel.listen('.ai-image-created-event', ({ data }) => {
    //         toast.success('Image created successfully')
    //         setResponse(data);
    //     })
    //     return () => {
    //         !!channel && channel?.stopListening('ai-image-created-event');
    //         window.Echo.leaveChannel(`ai-image.${response?.uuid}`);
    //     };
    // }, [response?.uuid])

    const fetchLast = async () => {
        const { data } = await window.axios.get(route('prompt.last'));
        setResponse(data);
    }

    return { response, setResponse, fetchLast, selected, setSelected };
}

export const usePromptImageListener = () => {
    const setResponse = useAiPromptResponseStore(state => state.setResponse);

    useAiPromptResponseStore.subscribe((state) => state.response, (response) => {
        if (response?.uuid) {
            console.log("Listening to :", response?.uuid)
            const channel = window.Echo.channel(`ai-image.${response?.uuid}`);
            channel.listen('.ai-image-created-event', ({ data }) => {
                toast.success('Image created successfully')
                setResponse(data);
            })
        }
    })
}
